import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Content from "../../components/Content"
import Gallery from "../../components/Gallery"

const galImgs = ['/page-images/img_1156.jpg']

export default function WhatWeDo() {
  return (
    <Layout breadcrumbs={["What we do"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

        <Content>
    <p>Crockard Building Design was established in 1993 by its principal, Ian Crockard, following a number of years working for Belfast based architect practices and qualifying from Queens University Belfast.&nbsp;</p>
<p>	As a practice, we believe we have a responsibility to protect and enhance the quality of our built environment and to create buildings that not only respond to the needs of our clients but also to the buildings&rsquo; context within their urban or rural landscape with each being a unique site-specific response.</p>
<p>	Within practical boundaries, we aim to assess the sustainability and impact of the building materials, systems and construction processes used in the project and also how these might affect the immediate surroundings and wider environment.</p>
</Content>

      <div class="region region-content-bottom">
        <MenuOne active="what-we-do" />
        <MenuTwo active="" items={[
          "initial-designs",
          "planning-approvals",
          "building-control",
          "site-operations",
          "other-services"
        ]} />
      </div>

  </Layout>

  )
}
